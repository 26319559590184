import { FunctionComponent } from "react";
import * as sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { showServerError } from "utilities/show-server-error";
import { Toaster } from "react-hot-toast";
import { IProps } from "./application.types";
import { shouldRequestRetry } from "@edgetier/utilities";
import ErrorFallback from "components-for/errors/error-fallback";
import { getRouterSetup } from "utilities-for/routes/get-router-setup";
import setDateFNSDefaultOptions from "date-fns/setDefaultOptions";
import { FeatureFlagsProvider } from "@edgetier/feature-flags";
import state from "feature-flags.json";
import { BASE_NAME, GLOBAL_DATA_STALE_TIME } from "./application.contants";
import "./application.scss";

setDateFNSDefaultOptions({ weekStartsOn: 1 });

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            onError: showServerError,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: shouldRequestRetry,
            staleTime: GLOBAL_DATA_STALE_TIME,
        },
    },
});

// If PUBLIC_URL is set to /pr/:prNumber, use it as the basename for the router so the user can navigate to
// /pr/:prNumber/explore, /pr/:prNumber/anomalies etc.
const router = createBrowserRouter(getRouterSetup(), { basename: BASE_NAME });

const Application: FunctionComponent<IProps> = () => {
    return (
        <FeatureFlagsProvider
            options={{
                state: { ...state, ts: state.ts ?? undefined },
                ...(process.env.NODE_ENV === "development" ? { preventFetch: true } : {}),
            }}
        >
            <Toaster position="bottom-right" toastOptions={{ style: { backgroundColor: "#34495e", color: "white" } }} />

            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools
                    initialIsOpen={false}
                    position="top-left"
                    toggleButtonProps={{ className: "react-query-devtools__button" }}
                />
                <RouterProvider router={router} />
            </QueryClientProvider>
        </FeatureFlagsProvider>
    );
};

export default sentry.withErrorBoundary(Application, {
    fallback: ErrorFallback,
    showDialog: true,
});
