import { IUser, Route } from "@edgetier/watchtower-types";
import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { axios } from "utilities/axios";

/**
 * Request information about the user
 * @returns     User data
 */
const requestUser = async () => {
    const { data } = await axios.get<IUser>(Route.UsersMe);
    return data;
};

/**
 * Hook that requests current user information
 */
export const useUser = <T = IUser>(configuration: UseQueryOptions<IUser, AxiosError, T> = {}) => {
    return useQuery<IUser, AxiosError, T>(Route.UsersMe, requestUser, {
        ...configuration,
        staleTime: Infinity,
    });
};
