import { IClient, Permission } from "@edgetier/watchtower-types";
import { ReactNode } from "react";
import { IndexRouteObject, LazyRouteFunction, NonIndexRouteObject, Params, RouteObject } from "react-router-dom";
import { FEATURE_FLAGS } from "types-for/feature-flags";

type IRouteHandleSimplePermission = Permission | Permission[];
type IRouteHandleComplexPermission = {
    type: "tags";
};
type IEnabledClientKeyConfiguration = {
    clientKey: keyof IClient;
    validation: (value?: any) => any;
};
type IEnabledFeatureFlagConfiguration = {
    validation: { featureFlag: FEATURE_FLAGS | Array<FEATURE_FLAGS> };
};
type IEnabledBasicConfiguration = {
    validation: () => boolean;
};
export type IEnabledConfiguration =
    | IEnabledClientKeyConfiguration
    | IEnabledFeatureFlagConfiguration
    | IEnabledBasicConfiguration;

export const isEnabledFeatureFlagConfiguration = (
    enabledConfiguration: IEnabledConfiguration
): enabledConfiguration is IEnabledFeatureFlagConfiguration => {
    return "validation" in enabledConfiguration && "featureFlag" in enabledConfiguration.validation;
};

export type IRouteHandleCrumbItem = { url?: string; title: ReactNode };
export type IRouteHandleBreadcrumbFunctionReturn = IRouteHandleCrumbItem | Array<IRouteHandleCrumbItem>;
type IRouteHandleBreadcrumbFunction = (params: Params) => IRouteHandleBreadcrumbFunctionReturn;

export type IRouteHandlePermissionValue = IRouteHandleSimplePermission | IRouteHandleComplexPermission;
export type IRouteHandleEnabledValue = IEnabledConfiguration | Array<IEnabledConfiguration>;
export type IRouteHandleBreadcrumbValue = IRouteHandleSimplePermission | IRouteHandleComplexPermission;

export type IRouteHandlePermissions = {
    permission: IRouteHandlePermissionValue;
    operation: "AND" | "OR";
};
export type IRouteHandleEnabled = {
    enabled: IRouteHandleEnabledValue;
};
export type IRouteHandleBreadcrumb = {
    crumb: IRouteHandleBreadcrumbFunction;
};
export type IRouteHandle = Partial<IRouteHandlePermissions> &
    Partial<IRouteHandleBreadcrumb> &
    Partial<IRouteHandleEnabled>;

export interface IIndexRouteConfig extends IndexRouteObject {
    path?: RouteObject["path"];
    index: true;
    lazy?: LazyRouteFunction<any>;
    loader?: RouteObject["loader"];
    handle?: IRouteHandle;
    initialProperties?: Record<string, any>;
    isRoute?: boolean;
    children?: undefined;
    permission?: Permission;
}

export interface INonIndexRouteConfig extends Omit<NonIndexRouteObject, "children"> {
    index?: false;
    path?: RouteObject["path"];
    lazy?: LazyRouteFunction<any>;
    loader?: RouteObject["loader"];
    handle?: IRouteHandle;
    initialProperties?: Record<string, any>;
    isRoute?: boolean;
    children?: IRouteHierarchy;
    permission?: Permission;
}

export type IRouteConfig = IIndexRouteConfig | INonIndexRouteConfig;

export type IRouteHierarchy = {
    [routeName in PageNames]?: IRouteConfig;
} & { [keys: string]: IRouteConfig };

export interface IRouteConfiguration {
    importPaths: string;
    initialProps?: Record<string, any>;
    children?: IRouteConfiguration[];
}

export const isIRouteHandleSimplePermission = (
    permissionConfiguration: IRouteHandlePermissionValue
): permissionConfiguration is IRouteHandleSimplePermission => {
    return typeof permissionConfiguration === "number" || Array.isArray(permissionConfiguration);
};

export enum PageNames {
    Login = "Login",
    Authenticate = "Authenticate",
    AuthenticateRefresh = "AuthenticateRefresh",
    NoMatch = "NoMatch",
    Main = "Main",
    Agent = "Agent",
    Agents = "Agents",
    Reviews = "Reviews",
    AgentGroup = "AgentGroup",
    Anomalies = "Anomalies",
    Anomaly = "Anomaly",
    CustomerOverview = "CustomerOverview",
    CustomReports = "CustomReports",
    CustomReportsDashboard = "CustomReportsDashboard",
    Explore = "Explore",
    ExploreTags = "ExploreTags",
    Interaction = "Interaction",
    ReviewInteraction = "ReviewInteraction",
    Live = "Live",
    ManageTags = "ManageTags",
    ViewPhraseTriggers = "ViewPhraseTriggers",
    CreatePhraseTriggers = "CreatePhraseTriggers",
    ManagePhraseTriggers = "ManagePhraseTriggers",
    PhraseTriggersReport = "PhraseTriggersReport",
    Permission = "Permission",
    Scorecards = "Scorecards",
    CreateScorecard = "CreateScorecard",
    EditScorecard = "EditScorecard",
    SpellingConfiguration = "SpellingConfiguration",
    CreateTeamConfiguration = "CreateTeamConfiguration",
    EditTeamConfiguration = "EditTeamConfiguration",
    Teams = "Teams",
    TeamsConfiguration = "TeamsConfiguration",
    Users = "Users",
}

export enum NavigationCategories {
    AgentReview = "Agent Review",
    Anomalies = "Anomalies",
    Explore = "Explore",
    CustomerOverview = "CustomerOverview",
    Configuration = "Configuration",
    UserManagement = "User Management",
}

export enum NavigationLabels {
    Agent = "Agent",
    Agents = "Agents",
    Reviews = "Reviews",
    Anomalies = "Anomalies",
    Explore = "Explore",
    ExploreTags = "Explore Tags",
    CustomerOverview = "Customers",
    Live = "Live",
    CustomReports = "Custom Reports",
    TagsAndTriggers = "Tags and Triggers",
    AgentGroups = "Agent Groups",
    Scorecards = "Scorecards",
    SpellingExemptions = "Spelling Exemptions",
    Permissions = "Roles and Permissions",
    Users = "Users",
    TeamsConfiguration = "Data Access Control",
}

export type AbsolutePageFunction = (...args: Array<string>) => string;

export type IMainRoutes =
    | PageNames.Explore
    | PageNames.Agent
    | PageNames.Live
    | PageNames.Agents
    | PageNames.Reviews
    | PageNames.ExploreTags
    | PageNames.CustomerOverview;
