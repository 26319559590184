export enum FEATURE_FLAGS {
    SpellingGrammarMetrics = "spelling_grammar_metrics",
    GrammarMetric = "grammar_metric",
    WritingIssuesFilter = "writing_issues_filter",
    CustomizableExploreCharts = "customizable_explore_charts",
    ScorecardsAgentsLogin = "scorecards_agents_login",
    AgentsTableRefactor = "agents_table_refactor",
    AgentReviewScorecards = "agent_review_scorecards",
    EmpathySentiment = "empathy_sentiment",
    ConfusionSentiment = "confusion_sentiment",
    ExploreFiltersPresets = "explore_filters_presets",
    InteractionGroupSummary = "interaction_group_summary",
    NewMetricDistributionSelector = "new_metric_distribution_selector",
    InternalFeatureFlagToggler = "internal_feature_flag_toggler",
    CustomerOverview = "customer_overview",
    CustomizableExploreChartsTotals = "customizable_explore_charts_totals",
    NewInteractionCountPercentageMetric = "new_interaction_count_percentage_metric",
}
