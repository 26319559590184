import Qs from "qs";
import { endOfDay, endOfWeek, startOfDay, startOfWeek, subDays, subMonths, subWeeks } from "date-fns";
import { InteractionType } from "@edgetier/watchtower-types";
import { IMainRoutes, PageNames } from "types-for/routes";
import { AbsolutePaths } from "constants/routes";

/**
 * Create default URL parameters that should be passed to some routes.
 */
export const getDefaultRouteUrls = (): {
    [index in keyof Pick<typeof PageNames, IMainRoutes>]: { parameters: Object; url: string };
} => {
    const liveParameters = { interactionType: InteractionType.Chat };
    const agentsParameters = {
        endDateTime: endOfDay(subDays(new Date(), 1)),
        startDateTime: startOfDay(subDays(new Date(), 14)),
    };
    const exploreParameters = {
        endDateTime: endOfDay(new Date()),
        startDateTime: startOfDay(subDays(new Date(), 13)),
    };
    const reviewsParameters = {
        createEndDateTime: endOfDay(new Date()),
        createStartDateTime: startOfDay(subDays(new Date(), 13)),
    };

    // Email is not yet ready to review agents.
    const agentParameters = { interactionType: InteractionType.Chat };

    // Default to the last full week.
    const exploreTagParameters = {
        endDateTime: endOfWeek(subWeeks(new Date(), 1)),
        startDateTime: subWeeks(startOfWeek(new Date()), 1),
    };

    // Default to last 6 months
    const customerOverviewParameters = {
        endDateTime: endOfDay(new Date()),
        startDateTime: startOfDay(subMonths(new Date(), 6)),
    };

    return {
        [PageNames.Explore]: {
            parameters: exploreParameters,
            url: `${AbsolutePaths[PageNames.Explore]()}${Qs.stringify(exploreParameters, { addQueryPrefix: true })}`,
        },
        [PageNames.Agent]: {
            parameters: agentParameters,
            url: `${AbsolutePaths[PageNames.Agent]()}${Qs.stringify(agentParameters, { addQueryPrefix: true })}`,
        },
        [PageNames.Live]: {
            parameters: liveParameters,
            url: `${AbsolutePaths[PageNames.Live]()}${Qs.stringify(liveParameters, { addQueryPrefix: true })}`,
        },
        [PageNames.Agents]: {
            parameters: agentsParameters,
            url: `${AbsolutePaths[PageNames.Agents]()}${Qs.stringify(agentsParameters, { addQueryPrefix: true })}`,
        },
        [PageNames.Reviews]: {
            parameters: reviewsParameters,
            url: `${AbsolutePaths[PageNames.Reviews]()}${Qs.stringify(reviewsParameters, { addQueryPrefix: true })}`,
        },
        [PageNames.ExploreTags]: {
            parameters: exploreTagParameters,
            url: `${AbsolutePaths[PageNames.ExploreTags]()}${Qs.stringify(exploreTagParameters, {
                addQueryPrefix: true,
            })}`,
        },
        [PageNames.CustomerOverview]: {
            parameters: customerOverviewParameters,
            url: `${AbsolutePaths[PageNames.CustomerOverview]()}${Qs.stringify(customerOverviewParameters, {
                addQueryPrefix: true,
            })}`,
        },
    };
};
