import Axios from "axios";
import Qs from "qs";
import { decamelizeKeys } from "humps";
import { getAccessToken } from "utilities/access-token";
import { isValid, parseISO } from "date-fns";
import { getClientId } from "./client-id";

export const BASE_URL = "/api";

// Dates formats the backend might send.
export const DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

const axios = Axios.create({
    baseURL: BASE_URL,
    paramsSerializer: (parameters) =>
        Qs.stringify(decamelizeKeys(parameters), {
            arrayFormat: "repeat",
            skipNulls: true,
        }),
    transformResponse: (data) => {
        try {
            return JSON.parse(data, (key, value) => {
                // Attempt to transform strings to dates.
                if (typeof value === "string" && DATE_PATTERN.test(value)) {
                    try {
                        const date = parseISO(value);
                        return isValid(date)
                            ? parseISO(
                                  value.replace(
                                      /([+-])(\d{2}:\d{2})/,
                                      (_, sign, offset) => `${sign === "+" ? "-" : "+"}${offset}`
                                  )
                              )
                            : value;
                    } catch {
                        return value;
                    }
                }

                if (key === "interactionType" && typeof value === "string") {
                    return value.toUpperCase().replaceAll("_", " ");
                }

                if (key === "languageIsoCode" && typeof value === "string") {
                    return value.toLowerCase();
                }

                return value;
            });
        } catch {
            return data;
        }
    },
});

/**
 * Put the access token in a header.
 */
export const createAuthorisationHeader = () => `Bearer ${getAccessToken()}`;

axios.interceptors.request.use((request) => {
    const clientId = getClientId();
    if (clientId !== null) {
        request.headers["WatchTower-Client-ID"] = clientId;
    }
    request.headers["Authorization"] = createAuthorisationHeader();
    return request;
});

export { axios };

export default Axios;
